<template>
  <section>
    <el-table
      :data="tableData"
      border
      height="650"
      style="width: 100%"
      v-loading="loading"
      @header-click="headerClick"
      :header-cell-class-name="headerCellClassName"
    >
      <el-table-column
        align="center"
        prop="rptTime"
        label="统计时间"
      ></el-table-column>
      <el-table-column align="center" prop="userType" label="客户类型">
        <template slot-scope="scope">
          <span>{{ scope.row.userType == 0 ? "新客" : "老客" }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="cnt" label="单数"></el-table-column>
      <el-table-column
        align="center"
        prop="successCnt"
        label="成功单数"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="successRate"
        label="订单成功率"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="userCnt"
        label="人数"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="successUserCnt"
        label="成功人数"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="successUserRate"
        label="客户成功率"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="successAmt"
        label="成功金额"
      ></el-table-column>
    </el-table>
    <DialogEachart
      :data="eachartData"
      :dialogVisible="dialogVisible"
      :xData="xData"
      @search="search"
      @closeDialog="closeDialog"
      ref="dialogEachart"
    ></DialogEachart>
  </section>
</template>

<script>
import { getReportTbApi } from "../../api/report/index";
import echartMinixs from "./mixins/echartMinixs.js";
import DialogEachart from "./components/DialogEachart.vue";
export default {
  mixins: [echartMinixs],
  data() {
    return {
      loading: false,
      tableData: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      this.loading = true;
      let res = await getReportTbApi({});
      if (res.code == "0000") {
        this.loading = false;
        this.tableData = res.data;
      }
    },
  },
  components: {
    DialogEachart,
  },
};
</script>

<style lang="scss" scoped>
:deep(.table_header) {
  color: blue;

  cursor: pointer;
}
:deep(.table_header .cell) {
  text-decoration: underline;
}
</style>
